/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Poppins', 'Helvetica-Neue', 'Roboto-Light', sans-serif !important;
  --ion-background-color: rgba(61, 82, 72, 0.6);

  background-image: url('../static/background_photo.svg');
  background-repeat: no-repeat;
  background-size: cover;

  --ion-toolbar-background: #3d5248;

  --ion-color-primary: #84b89e;
  --ion-color-primary-rgb: 132, 184, 158;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #74a28b;
  --ion-color-primary-tint: #90bfa8;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  /** primary **/
  --ion-color-primary: #84b89e;
  --ion-color-primary-rgb: 132, 184, 158;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #74a28b;
  --ion-color-primary-tint: #90bfa8;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #3d5248;
  --ion-color-tertiary-rgb: 61, 82, 72;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7b9d8d;
  --ion-color-tertiary-tint: #b6c9c0;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #3d5248;
  --ion-color-dark-rgb: 61, 82, 72;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #36483f;
  --ion-color-dark-tint: #50635a;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-action-sheet.custom-action-sheet {
  --background: #74a28b;
  --button-color: white;
}

ion-item {
  --background: none;
}

ion-title {
  --color: white;
  text-align: center;
  font-weight: 200;
  font-size: 14px;
}

ion-toolbar {
  --padding-top: 5px;
  --padding-bottom: 5px;
}

ion-back-button {
  color: white;
}

ion-loading {
  --background: none;
  --spinner-color: yellow;
  --height: 300px;
}

ion-menu-button {
  color: white;
}

ion-tab-bar {
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-tab-button {
  color: white;
  font-size: 11px;
  stroke: #fff;
  fill: #fff;
}

.tab-selected {
  color: #026536;
  stroke: #026536;
  fill: #026536;
}

ion-tab-button ion-badge {
  background-color: #ff0000;
  font-size: 11px;
  top: 0px;
  right: 2px;
  padding-right: 0px;
  padding-left: 0px;
  width: 18px;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;

  -webkit-text-fill-color: inherit !important;

  /* -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset; */

  transition: background-color 5000s ease-in-out 0s;
}
