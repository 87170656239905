.Header {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 2px gray solid;
}

.Toolbar {
    
    box-shadow: none;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}