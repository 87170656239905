ion-modal {
  --height: 100%;
  --backdrop-opacity: 0.7;
}

@media (min-width: 768px) and (max-width: 1023px) {
  ion-modal {
    --height: 80%;
  }
}

@media (min-width: 1024px) {
  ion-modal {
    --height: 80%;
  }
}
