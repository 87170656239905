ion-alert.account-delete {
  --backdrop-opacity: 0.8;
  --background: #3d5248;
}

ion-alert.account-delete .alert-title {
  color: #eb445a;
}

ion-alert.account-delete .alert-sub-title {
  color: #eb445a;
}

ion-alert.account-delete .alert-message {
  color: white;
}

ion-alert.account-delete .alert-button-confirm {
  background: #eb445a;
  color: white;
}