.ionToolbar {
  --background: url('../../static/background_photo.svg');
  --opacity: 0.3;
  background-color: hsl(130, 21.4%, 16.5%);
}

.ionContent {
  --background: url('../../static/background_photo.svg');
  --overflow: hidden;
  background-color: hsl(130, 21.4%, 16.5%);
  position: relative;
}

.contentBackground {
  position: absolute;
  opacity: 0.7;
  background-color: hsl(130, 21.4%, 16.5%);
  height: 100%;
  width: 100%;
}

.ionBadge {
  position: absolute;
  right: 8px;
  top: 12px;
  background-color: red;
  z-index: 10;
}
