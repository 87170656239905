@tailwind base;
@tailwind components;
@tailwind utilities;

ion-loading {
  cursor: wait;
}

ion-item {
  --highlight-height: 2px;
  --highlight-color-invalid: #ff0000;
}

.ion-thumbnail {
  --size: 70px;
  --border-radius: 15px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  ion-toolbar {
    height: 80px;
    display: flex;
  }

  ion-back-button {
    font-size: 20px;
  }

  ion-loading {
    width: calc(100% + 220px);
  }

  ion-split-pane {
    --side-width: 220px;
  }

  .ion-thumbnail {
    --size: 80px;
  }

  .avatar {
    margin-left: 20%;
  }
}

@media (min-width: 1024px) {
  ion-toolbar {
    height: 100px;
    display: flex;
  }

  ion-back-button {
    font-size: 25px;
  }

  ion-loading {
    width: calc(100% + 350px);
  }

  ion-split-pane {
    --side-width: 350px;
  }

  .ion-thumbnail {
    --size: 100px;
  }

  .avatar {
    margin-left: 38%;
  }
}
